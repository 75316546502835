const messageEmailInvalid = 'E-mail Inválido!';
const messageInvalid = 'Valor do campo não é válido.';

export const requiredLength = v =>
  v === undefined || v === null || v.length == 0
    ? `Este campo é obrigatório`
    : true;

export const required = v => !!v || 'Este campo é obrigatório';

export const email = v => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (v) {
    return pattern.test(v) || messageEmailInvalid;
  } else {
    return true;
  }
};

export const cpf = v => {
  if (v === null || v === undefined || v === '') return false;

  v = v.replace(/-|\./g, '');

  if (!/^[0-9]+$/.test(v)) return messageInvalid;

  const invalidValues = [
    '191',
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ];

  if (v.length !== 11 || invalidValues.includes(v)) {
    return messageInvalid;
  }

  let total, index, leftover, verifyingDigit, counter;

  if (v.length >= 11) {
    index = 2;
    total = 0;
    leftover = 0;
    verifyingDigit = 0;

    for (counter = -9; counter <= -1; counter++) {
      total = total + Math.abs(v.substr(Math.abs(counter) - 1, 1) * index);
      index = index + 1;
    }

    leftover = total % 11;

    leftover === 0 || leftover === 1
      ? (verifyingDigit = 0)
      : (verifyingDigit = 11 - leftover);

    if ('' + v.substr(9, 1) != '' + verifyingDigit) {
      return messageInvalid;
    }

    index = 2;
    total = 0;
    leftover = 0;
    verifyingDigit = 0;

    for (counter = -10; counter <= -1; counter++) {
      total = total + Math.abs(v.substr(Math.abs(counter) - 1, 1) * index);
      index = index + 1;
    }

    leftover = total % 11;

    leftover === 0 || leftover === 1
      ? (verifyingDigit = 0)
      : (verifyingDigit = 11 - leftover);

    if ('' + v.substr(10, 1) !== '' + verifyingDigit) {
      return messageInvalid;
    }
  }

  return true;
};

export const cnpj = v => {
  if (v === null || v === undefined || v === '') return false;

  v = v.replace(/-|\.|\//g, '');

  if (!/^[0-9]+$/.test(v)) return messageInvalid;

  const invalidValues = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ];

  if (v.length !== 14 || invalidValues.includes(v)) {
    return messageInvalid;
  }

  if (v.length >= 14) {
    let total = 0,
      index = 2,
      leftover = 0,
      verifyingDigit = 0,
      counter = 0;

    for (counter = -12; counter <= -1; counter++) {
      total = total + Math.abs(v.substr(Math.abs(counter) - 1, 1)) * index;

      index === 9 ? (index = 2) : (index = index + 1);
    }

    leftover = total % 11;

    leftover === 0 || leftover === 1
      ? (verifyingDigit = 0)
      : (verifyingDigit = 11 - leftover);

    if ('' + v.substr(12, 1) !== '' + verifyingDigit) {
      return messageInvalid;
    }

    index = 2;
    total = 0;
    leftover = 0;
    verifyingDigit = 0;

    for (counter = -13; counter <= -1; counter++) {
      total = total + Math.abs(v.substr(Math.abs(counter) - 1, 1)) * index;

      index === 9 ? (index = 2) : (index = index + 1);
    }

    leftover = total % 11;

    leftover === 0 || leftover === 1
      ? (verifyingDigit = 0)
      : (verifyingDigit = 11 - leftover);

    if ('' + v.substr(13, 1) !== '' + verifyingDigit) {
      return messageInvalid;
    }
  }

  return true;
};

export const zipcode = v => {
  const pattern = /(^[0-9]{5})-?([0-9]{3}$)/;

  return pattern.test(v) || messageInvalid;
};
