<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="450"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal(false)">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-text-field
                id="name"
                name="name"
                label="Nome"
                outlined
                required
                :rules="[required]"
                v-model="model.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
            v-permission="{ roles: ['A1'], operator: '===' }"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { BusinessUnitService } from '@/services/api/businessUnit';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      id: null,
      name: null
    }
  }),

  created() {
    if (this.edit) {
      this.getById();
    }
  },

  methods: {
    async getById() {
      try {
        const businessUnitService = new BusinessUnitService();
        const { status, data } = await businessUnitService.getById(
          this.selectedItem.id
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeModal(search) {
      this.$emit('closeModal', search);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      if (this.edit) {
        this.update();
      } else {
        this.create();
      }
    },

    async create() {
      try {
        const businessUnitService = new BusinessUnitService();
        const { status } = await businessUnitService.postBusinessUnit(
          this.model
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update() {
      try {
        const businessUnitService = new BusinessUnitService();
        const { status } = await businessUnitService.putBusinessUnit(
          this.model
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
