import {
  required,
  requiredLength,
  email,
  cpf,
  cnpj,
  zipcode
} from '@/helpers/validations';

export const rulesMixin = {
  data() {
    return {
      required,
      requiredLength,
      email,
      cpf,
      cnpj,
      zipcode
    };
  }
};
